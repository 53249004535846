import { TextBold, TextRegular } from '@components/typography';
import { Box, Flex } from '@chakra-ui/react';
import { FilterRemoveIcon } from '@assets/icons/index';
import ButtonOutline from '@components/buttons/buttonOutline';
import ButtonRegular from '@components/buttons/buttonRegular';
import { ConditionalComponent } from '@components/functionComponent';
import { useState } from 'react';
import { emptyFilterState, initialFilterState } from './const';
import useGetDC from '@domain/dc/useGetDC';
import Select from '@components/filter/select';
import checkFilterInformation from '@pages/AuditTrailParahub/helpers/checkFilterInformation';
import { useFilter } from '@pages/AuditTrailParahub/state/filters';
import '../filterButton/styles.css';
import useGetParahubList from '@domain/parahub/useGetParahubList';
import useGetSelloutType from '@domain/selloutType/useGetSelloutType';
import FilterMultipleDate from '@components/filter/multipleDate';
import useGetStatus from '@domain/auditTrail/useGetStatus';
import { isEmpty } from 'lodash';

const FilterList = ({ isOpen, onClose }) => {
    const filters = useFilter();
    const [tempFilter, setTempFilter] = useState(initialFilterState);

    const isOpenFilterInformation = checkFilterInformation(filters);
    const dc = useGetDC({ enabled: tempFilter?.enabledDC, search: tempFilter?.dcSearch });
    const parahub = useGetParahubList({ search: tempFilter?.parahubSearch });
    const selloutType = useGetSelloutType({ enabled: false });
    const status = useGetStatus();

    const onResetFilter = () => {
        setTempFilter(emptyFilterState);
        filters.onClearFilter();
        onClose();
    };

    const onApplyFilter = () => {
        filters.setFilter({
            dcName: tempFilter?.dc?.map((item) => item?.name),
            parahub: tempFilter?.parahub?.map((item) => item?.id),
            selloutType: tempFilter?.selloutType?.name,
            status: tempFilter?.status?.name,
            dateUpload: tempFilter?.dateUpload,
            dateSellout: tempFilter?.dateSellout,
            page: 1,
        });
        onClose();
    };

    const onRemoveFilterItem = (filterName, tempFilterName) => {
        filters.setFilter({ [filterName]: [] });
        setTempFilter({ ...tempFilter, [tempFilterName]: [] });
    };

    const onChangeRangeDate = ({ dates, type }) => {
        let [startDate, endDate] = dates;

        setTempFilter({ [type]: { startDate, endDate } });
    };

    return (
        <Box className="filters-parahub__container">
            {/* FILTER LIST  */}

            <ConditionalComponent statement={isOpen}>
                <Flex className="box-filters fade-animation-bottom">
                    <Flex gap="16px 24px" flexWrap="wrap">
                        <Select
                            selected={tempFilter?.dc}
                            onCloseOptions={() => setTempFilter({ ...tempFilter, salesAreaSearch: '' })}
                            title="Distribution Center"
                            placeholder="Pilih DC"
                            width="240px"
                            isGetAll={true}
                            refetchData={dc?.refetch}
                            data={dc?.data}
                            isLoading={dc?.isLoading}
                            onSearch={(query) => setTempFilter({ ...tempFilter, dcSearch: query, enabledDC: true })}
                            onChangeFilter={(value) => setTempFilter({ ...tempFilter, dc: value })}
                        />

                        <Select
                            selected={tempFilter?.parahub}
                            title="Parahub"
                            placeholder="Pilih Parahub"
                            width="240px"
                            isGetAll={true}
                            refetchData={parahub?.refetch}
                            data={parahub?.data}
                            isLoading={parahub?.isLoading}
                            onCloseOptions={() => setTempFilter({ ...tempFilter, parahubSearch: '' })}
                            onSearch={(query) => setTempFilter({ ...tempFilter, parahubSearch: query })}
                            onChangeFilter={(value) => setTempFilter({ ...tempFilter, parahub: value })}
                        />

                        <FilterMultipleDate
                            value={tempFilter?.dateSellout}
                            width="240px"
                            title="Tanggal Sellout"
                            placeholder="Pilih tanggal sellout"
                            onChange={(dates) => onChangeRangeDate({ dates, type: 'dateSellout' })}
                        />
                        <FilterMultipleDate
                            width="240px"
                            value={tempFilter?.dateUpload}
                            title="Tanggal Dikirim"
                            placeholder="Pilih tanggal kirim"
                            onChange={(dates) => onChangeRangeDate({ dates, type: 'dateUpload' })}
                        />

                        <Select
                            selected={tempFilter?.selloutType}
                            width="240px"
                            title="Tipe Sellout"
                            placeholder="Pilih Tipe Sellout"
                            isMultiple={false}
                            isGetAll={true}
                            refetchData={selloutType?.refetch}
                            data={selloutType?.data}
                            isLoading={selloutType?.isLoading}
                            isCanSearch={false}
                            onChangeFilter={(value) => setTempFilter({ ...tempFilter, selloutType: value })}
                        />

                        <Select
                            selected={tempFilter?.status}
                            width="240px"
                            isMultiple={false}
                            title="Status"
                            placeholder="Pilih Status"
                            isGetAll={true}
                            refetchData={status?.refetch}
                            data={status?.data}
                            isLoading={status?.isLoading}
                            isCanSearch={false}
                            onChangeFilter={(value) => setTempFilter({ ...tempFilter, status: value })}
                        />
                    </Flex>
                    <Flex className="filter-buttons__container">
                        <ButtonOutline
                            component="Reset All"
                            width="79px"
                            height="36px"
                            color="var(--color-primary)"
                            onClick={onResetFilter}
                        />
                        <ButtonRegular
                            component="Apply Filter"
                            width="98px"
                            height="36px"
                            color="var(--color-primary)"
                            onClick={onApplyFilter}
                        />
                    </Flex>
                </Flex>
            </ConditionalComponent>

            {/* FILTER SELECTED  */}
            <ConditionalComponent statement={!isOpen && isOpenFilterInformation}>
                <Flex className="filter-selected__container">
                    <TextBold>Filters by:</TextBold>

                    <ConditionalComponent statement={filters?.dcName?.length > 0}>
                        <Flex className="filter-selected__item">
                            <TextBold>Distribution Center:</TextBold>
                            <TextRegular className="selected-text">{filters?.dcName?.length} Terpilih</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('dcName', 'dc')} />
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={filters?.parahub?.length > 0}>
                        <Flex className="filter-selected__item">
                            <TextBold>Parahub:</TextBold>
                            <TextRegular className="selected-text">{filters?.parahub?.length} Terpilih</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('parahub', 'parahub')} />
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={!isEmpty(filters?.selloutType)}>
                        <Flex className="filter-selected__item">
                            <TextBold>Tipe Sellout:</TextBold>
                            <TextRegular className="selected-text">{filters?.selloutType}</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('selloutType', 'selloutType')} />
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={!isEmpty(filters?.status)}>
                        <Flex className="filter-selected__item">
                            <TextBold>Status:</TextBold>
                            <TextRegular className="selected-text">{filters?.status}</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('status', 'status')} />
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={!isEmpty(filters?.dateSellout)}>
                        <Flex className="filter-selected__item">
                            <TextBold>Tanggal Sellout:</TextBold>
                            <TextRegular className="selected-text">Terpilih</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('dateSellout', 'dateSellout')} />
                        </Flex>
                    </ConditionalComponent>

                    <ConditionalComponent statement={!isEmpty(filters?.dateUpload)}>
                        <Flex className="filter-selected__item">
                            <TextBold>Tanggal Dikirim</TextBold>
                            <TextRegular className="selected-text">Terpilih</TextRegular>
                            <FilterRemoveIcon onClick={() => onRemoveFilterItem('dateUpload', 'dateUpload')} />
                        </Flex>
                    </ConditionalComponent>

                    <ButtonOutline
                        component="Reset All"
                        width="79px"
                        height="36px"
                        color="var(--color-primary)"
                        onClick={onResetFilter}
                    />
                </Flex>
            </ConditionalComponent>
        </Box>
    );
};

export default FilterList;
