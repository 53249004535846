import { every, isEmpty, max } from 'lodash';
import { chartOptions } from './const';

const { monthList } = require('@helpers/const');

const milyar = 1000000000;
const juta = 1000000;

const nominalFormatted = ({ data, nominal }) => {
    return data?.map((item) => item / nominal);
};

const getSisoTrendsResponse = (data, trends) => {
    const { trendSellin, trendSellout } = trends;

    const sellin = (trendSellin ? data?.sellin : []) ?? [];
    const sellout = (trendSellout ? data?.sellout : []) ?? [];

    const getMax = max([...sellin, ...sellout]);

    let obj = {
        type: 'Juta',
        nominal: juta,
    };

    if (getMax / milyar > 1) {
        obj.type = 'Milyar';
        obj.nominal = milyar;
    }

    const paddingLeft = `${Math.floor(getMax / obj?.nominal)}`.length * 6 + 56;

    const sellinData = nominalFormatted({ data: sellin, nominal: obj.nominal });
    const selloutData = nominalFormatted({ data: sellout, nominal: obj.nominal });

    const isEmptyData = every([...sellinData, ...selloutData], (value) => value === 0);
    const isDecimalUnder1 = every([...sellinData, ...selloutData], (value) => value < 1);

    return {
        typeNominal: obj?.nominal,
        chartHoverPaddingLeft: `${paddingLeft}px`,
        chartOptions: {
            ...chartOptions,
            scales: {
                ...chartOptions?.scales,
                y: {
                    min: 0,
                    max: isEmptyData ? 100 : isDecimalUnder1 ? 1 : null,
                    ticks: {
                        stepSize: Math.pow(10, Math.floor(Math.log10(getMax / obj?.nominal))),
                        color: '#2A3D4A',
                        padding: 12,
                        callback: function (value) {
                            return Math.floor(value); // Remove decimals
                        },
                    },
                    title: {
                        color: '#2A3D4A',
                        display: true,
                        text: `Value (${obj?.type} Rupiah)`,
                        font: {
                            size: 12,
                            family: 'Lato',
                            weight: 600,
                        },
                    },
                    grid: {
                        color: '#EBEBEB',
                    },
                },
            },
        },
        chartData: {
            labels: monthList,
            datasets: [
                {
                    label: 'Sell-in',
                    data: sellinData,
                    borderColor: '#D1A400',
                    backgroundColor: '#D1A400',
                    pointRadius: 2,
                    borderWidth: 2,
                    tension: 0,
                },
                {
                    label: 'Sell-out',
                    data: selloutData,
                    borderColor: '#005B99',
                    backgroundColor: '#2C54BA',
                    pointRadius: 2,
                    borderWidth: 2,
                    tension: 0,
                },
            ],
        },
    };
};

export default getSisoTrendsResponse;
