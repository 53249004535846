import { create } from 'zustand';

const state = {
    dcName: [],
    parahub: [],
    selloutType: {},
    page: 1,
    limit: 10,
    status: {},
    dateSellout: {},
    dateUpload: {},
};

export const useFilter = create((set) => ({
    ...state,
    setFilter: (newState) => set((state) => ({ ...state, ...newState })),
    onClearFilter: () =>
        set(() => ({
            ...state,
        })),
    resetFilter: () => set(() => ({ ...state })),
}));
