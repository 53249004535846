import { Box, Flex, Modal, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import { CloseIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { CloseRedIcon, CopyBlueIcon, ExportIcon, QuestionBlackIcon } from '@assets/icons/index';

import styles from './styles.module.css';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import { useMainState } from '@pages/AuditTrailParahub/state/state';
import { upperCase, upperFirst } from 'lodash';
import getSummaryDetail from '@pages/AuditTrailParahub/helpers/getSummaryDetail';
import useGetAuditTrailParahubErrorDetail from '@domain/parahub/useGetAuditTrailParahubErrorDetail';
import { onDownloadFile } from '@pages/AuditTrail/helpers/crud';
import { useDownloadNotification } from '@store/downloadNotification';

const DetailModal = ({ data }) => {
    const { onChangeDownloadNotification } = useDownloadNotification();
    const { onChangeMainState } = useMainState();
    const [selectedTab, setSelectedTab] = useState(1);
    const summary = getSummaryDetail(data);
    const errorDetail = useGetAuditTrailParahubErrorDetail(data);

    const onClickExport = () => {
        onDownloadFile({
            type: `parahub`,
            path: data?.processed_filename,
            onChangeDownloadNotification,
        });
    };

    const onClickHelpButton = () => {
        onChangeMainState({
            isOpenDetailModal: false,
            isOpenHelpModal: true,
        });
    };

    const onClose = () => {
        onChangeMainState({
            selectedParahub: {},
            isOpenDetailModal: false,
        });
    };

    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minWidth="560px" borderRadius="12px">
                <Box className={styles['detail-modal__container']}>
                    <Flex className={styles['head']}>
                        <TextBold className={styles['title']}>Detail Sellout Parahub</TextBold>
                        <CloseIcon fontSize="12px" cursor="pointer" onClick={onClose} />
                    </Flex>

                    <Box className={styles['body']}>
                        {/* TAB HEAD */}

                        <Flex className={styles['tab__container']}>
                            <Box
                                width="108px"
                                onClick={() => setSelectedTab(1)}
                                className={`${styles['tab__item']} ${
                                    selectedTab === 1 && styles['tab__item--actived']
                                }`}
                            >
                                <TextRegular className={styles['tab__text']}>Sellout</TextRegular>
                            </Box>

                            <Box
                                width="160px"
                                onClick={() => setSelectedTab(2)}
                                className={`${styles['tab__item']} ${
                                    selectedTab === 2 && styles['tab__item--actived']
                                }`}
                            >
                                <TextRegular className={styles['tab__text']}>Informasi Umum</TextRegular>
                            </Box>
                        </Flex>

                        {/* Sellout Information */}

                        <ConditionalComponent statement={selectedTab === 1}>
                            <Flex className={styles['sellout__information']}>
                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Sellout Date</TextBold>
                                    <IterationComponent
                                        arr={data?.date_sellout}
                                        render={(item, index) => (
                                            <TextRegular>
                                                {index + 1}. {item}
                                            </TextRegular>
                                        )}
                                    />
                                </Box>

                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Upload Type</TextBold>
                                    <TextRegular>{upperCase(data?.upload_type)}</TextRegular>
                                </Box>

                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Status</TextBold>
                                    <TextRegular
                                        p="4px 6px"
                                        width="max-content"
                                        color={
                                            data?.status === 'failed'
                                                ? 'var(--color-danger80)'
                                                : 'var(--color-success80)'
                                        }
                                        bg={
                                            data?.status === 'failed'
                                                ? 'var(--color-danger10)'
                                                : 'var(--color-success10)'
                                        }
                                    >
                                        {upperFirst(data?.status) ?? '-'}
                                    </TextRegular>
                                </Box>

                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Sellout Type</TextBold>
                                    <TextRegular>{upperFirst(data?.type_date)}</TextRegular>
                                </Box>

                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Uploader</TextBold>
                                    <TextRegular>{upperFirst(data?.name)}</TextRegular>
                                </Box>

                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Timestamp</TextBold>
                                    <TextRegular>{data?.date_uploaded}</TextRegular>
                                </Box>
                            </Flex>
                            <ConditionalComponent statement={data?.status === 'success'}>
                                <Box className={styles['summary__container']}>
                                    <Flex justifyContent="space-between" alignItems="center" mb="16px">
                                        <TextBold className={styles['summary__title']}>Summary</TextBold>
                                        <Flex gap="4px">
                                            <Box className={styles['summary__info']}>
                                                <TextRegular className={styles['summary__text']}>
                                                    {summary?.totalBrand} Brand
                                                </TextRegular>
                                            </Box>

                                            <Box className={styles['summary__info']}>
                                                <TextRegular className={styles['summary__text']}>
                                                    {summary?.totalSKU} SKU
                                                </TextRegular>
                                            </Box>

                                            <Box className={styles['summary__info']}>
                                                <TextRegular className={styles['summary__text']}>
                                                    {summary?.totalQty} QTY
                                                </TextRegular>
                                            </Box>
                                        </Flex>
                                    </Flex>

                                    <IterationComponent
                                        arr={summary?.brands}
                                        render={(brand) => (
                                            <Flex className={styles['summary_brand']}>
                                                <TextBold className={styles['summary__label']}>
                                                    {brand?.brandName}
                                                </TextBold>
                                                <TextRegular className={styles['summary__text']}>
                                                    {brand?.sku} SKU, {brand?.qty} QTY
                                                </TextRegular>
                                            </Flex>
                                        )}
                                    />
                                </Box>
                            </ConditionalComponent>
                            <ConditionalComponent statement={data?.status === 'failed'}>
                                <Box className={styles['error__container']}>
                                    <Flex justifyContent="space-between" alignItems="center" mb="16px">
                                        <TextBold className={styles['summary__title']}>Detail Masalah</TextBold>
                                        <CopyBlueIcon color="var(--color-primary)" />
                                    </Flex>

                                    <Box className={styles['error__box']}>
                                        <TextBold className={styles['error__title']}>
                                            {errorDetail?.data?.length} Kesalahan Ditemukan
                                        </TextBold>

                                        <Box className={styles['error-item__container']}>
                                            <ConditionalComponent
                                                statement={!errorDetail?.isLoading}
                                                fallback={
                                                    <Flex className={styles['error-loader__box']}>
                                                        <Spinner color="var(--color-danger50)" />
                                                    </Flex>
                                                }
                                            >
                                                <IterationComponent
                                                    arr={errorDetail?.data}
                                                    render={({ description }) => (
                                                        <Flex className={styles['error__item']}>
                                                            <CloseRedIcon />
                                                            <TextRegular className={styles['error__text']}>
                                                                {upperFirst(description)}
                                                            </TextRegular>
                                                        </Flex>
                                                    )}
                                                />
                                            </ConditionalComponent>
                                        </Box>
                                    </Box>
                                </Box>
                            </ConditionalComponent>
                            <Flex justifyContent="center" gap="12px">
                                <ConditionalComponent statement={data?.status === 'failed'}>
                                    <Flex className={styles['help-button']} onClick={onClickHelpButton}>
                                        <QuestionBlackIcon />
                                        <TextBold className={styles['help-button__text']}>Bantuan</TextBold>
                                    </Flex>
                                </ConditionalComponent>

                                <Flex className={styles['export-button']} onClick={onClickExport}>
                                    <ExportIcon />
                                    <TextBold className={styles['export-button__text']}>Export Data</TextBold>
                                </Flex>
                            </Flex>
                        </ConditionalComponent>

                        {/* Parahub Information */}

                        <ConditionalComponent statement={selectedTab === 2}>
                            <Flex className={styles['sellout__information']}>
                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Parahub ID</TextBold>
                                    <TextRegular>{data?.customer_id}</TextRegular>
                                </Box>

                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Parahub</TextBold>
                                    <TextRegular>{data?.nama_outlet}</TextRegular>
                                </Box>

                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Distribution Center</TextBold>
                                    <TextRegular>{data?.nama_dc}</TextRegular>
                                </Box>

                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Customer ID</TextBold>
                                    <TextRegular>{data?.customer_parahub_id}</TextRegular>
                                </Box>

                                <Box className={styles['information__item']}>
                                    <TextBold className={styles['information__title']}>Customer Name</TextBold>
                                    <TextRegular>{data?.customer_parahub_name}</TextRegular>
                                </Box>
                            </Flex>
                        </ConditionalComponent>
                    </Box>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default DetailModal;
