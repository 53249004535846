import authAxios from '@services/authAxios';

const fetchSelloutType = async () => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/upload-type/list`,
    });

    return data?.data?.page_data;
};

export default fetchSelloutType;
