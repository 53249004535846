import { useLocation, useNavigate } from 'react-router-dom';
import FolderStructure from '../../pages/folderStructure';
import Installation from '@pages/Documentation/pages/installation';
import ApplicationFlow from '@pages/Documentation/pages/flow';
import { useEffect } from 'react';
import GitFlow from '@pages/Documentation/pages/gitflow';
import queryString from 'qs';

const PagesContent = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const { pageName } = queryString.parse(search?.replace('?', ''));

    useEffect(() => {
        if (!pageName) navigate('/documentation?pageName=installation');
    }, []);

    const pages = {
        ['folder-stucture']: <FolderStructure />,
        ['installation']: <Installation />,
        ['application-flow']: <ApplicationFlow />,
        ['gitflow']: <GitFlow />,
    };

    return pages[pageName];
};

export default PagesContent;
