import { Box } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import TableSkeleton from './components/table';
import UploadSkeleton from './components/upload';
import BusinessSisoSkeleton from './components/siso';

import './styles.scss';
import StoreConfigurationDetailSkeleton from './components/storeConfigurationDetail';
import AuditTrailParahubSkeleton from './components/auditTrailParahub';

const DashboarSkeleton = () => {
    const { pathname } = useLocation();

    const getComponent = () => {
        let basePath = pathname.split('/').slice(0, 3).join('/');

        basePath = basePath.endsWith('/') ? basePath.slice(0, -1) : basePath;

        switch (basePath) {
            case '/upload-sellout':
            case '/upload-mapping':
                return <UploadSkeleton />;
            case '/business-siso':
                return <BusinessSisoSkeleton />;
            case '/audit-trail/parahub':
                return <AuditTrailParahubSkeleton />;
            case '/store-config/iseller':
                return pathname.replace(/\/$/, '').split('/')?.length > 3 ? (
                    <StoreConfigurationDetailSkeleton />
                ) : (
                    <TableSkeleton />
                );
            default:
                return <TableSkeleton />;
        }
    };

    return (
        <Box className="dashboard-skeleton" data-testid="dashboard-skeleton">
            {getComponent()}
        </Box>
    );
};

export default DashboarSkeleton;
