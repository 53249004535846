import { create } from 'zustand';

const state = {
    selectedParahub: false,
    isOpenDetailModal: false,
    isOpenHelpModal: false,
    selectedExportParahub: [],
};

export const useMainState = create((set) => ({
    ...state,
    onChangeMainState: (newState) => set((state) => ({ ...state, ...newState })),
    resetMainState: () => set(() => ({ ...state })),
}));
