import { isEmpty } from 'lodash';

const checkFilterInformation = (data) => {
    const { dcName, parahub, status, dateSellout, dateUpload, selloutType } = data;

    return (
        !isEmpty(dcName) ||
        !isEmpty(parahub) ||
        !isEmpty(status) ||
        !isEmpty(selloutType) ||
        !isEmpty(dateSellout) ||
        !isEmpty(dateUpload)
    );
};

export default checkFilterInformation;
