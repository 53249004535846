import { ExportIcon } from '@assets/icons/index';
import { Flex } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold } from '@components/typography';
import { useMainState } from '@pages/AuditTrailParahub/state/state';
import useGetAuditTrailParahubExport from '@domain/parahub/usGetAuditTrailParahubExport';

const Export = () => {
    const { selectedExportParahub } = useMainState();
    const exportMutation = useGetAuditTrailParahubExport(selectedExportParahub);

    const isExport = selectedExportParahub?.length > 0;

    return (
        <Flex
            className={`${styles['export-button']} ${!isExport && styles['export-button--disabled']}`}
            onClick={isExport && exportMutation.mutate}
        >
            <ExportIcon />
            <TextBold className={styles['export-button__text']}>Export Data</TextBold>
        </Flex>
    );
};

export default Export;
