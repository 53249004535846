import fetchAuditTrailParahubExport from '@data/parahub/fetchAuditTrailParahubExport';
import { useSnackbarNotification } from '@store/snackbarNotification';
import { saveAs } from 'file-saver';
import { useMutation } from '@tanstack/react-query';

const getAuditTrailParahubExport = ({ queries }) => {
    const { onChangeSnackbarNotification } = useSnackbarNotification();

    return useMutation({
        mutationFn: async () => {
            const data = await fetchAuditTrailParahubExport({ queries });

            const blob = new Blob([data], { type: 'text/csv' });

            saveAs(blob, `Audit Trail - Parahub.csv`);
        },
        onSuccess: async () => {
            onChangeSnackbarNotification({
                status: 'success',
                title: 'Sedang .',
                description: '',
                duration: 3,
            });
        },
        onError: (error) => {
            console.log(error);
            const errorMessage = error?.response?.data?.message ?? 'Terdapat sebuah kesalahan sistem';
            onChangeSnackbarNotification({
                status: 'error',
                title: 'Gagal melakukan sync.',
                description: errorMessage,
                duration: 3,
            });
        },
    });
};

export default getAuditTrailParahubExport;
