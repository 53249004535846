import getSelloutTypeResponse from '@domain/model/response/selloutType/getSelloutTypeResponse';
import getSelloutType from '@hooks/selloutType/getSelloutType';

const useGetSelloutType = (props) => {
    const response = getSelloutType({ enabled: props?.enabled });
    const data = getSelloutTypeResponse(response?.data);

    return { ...response, data };
};

export default useGetSelloutType;
