import fetchAuditTrailParahubSummary from '@data/parahub/fetchAuditTrailParahubSummary';
import { useQuery } from '@tanstack/react-query';

const getAuditTrailParahubSummary = () => {
    return useQuery({
        queryKey: ['audit-trail-parahub-summary'],
        queryFn: () => fetchAuditTrailParahubSummary(),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
    });
};

export default getAuditTrailParahubSummary;
