import fetchParahubList from '@data/parahub/fetchParahubList';
import { useQuery } from '@tanstack/react-query';

const getParahubList = ({ enabled }) => {
    return useQuery({
        queryKey: ['parahub-list'],
        queryFn: () => fetchParahubList(),
        refetchOnWindowFocus: false,
        throwOnError: (error) => error,
        retry: false,
        gcTime: 0,
        enabled,
    });
};

export default getParahubList;
