import getAuditTrailParahubRequest from '@domain/model/request/parahub/getAuditTrailParahub';
import getAuditTrailParahub from '@hooks/parahub/getAuditTrailParahub';

const useGetAuditTrailParahub = (filters) => {
    const queries = getAuditTrailParahubRequest(filters);

    return getAuditTrailParahub({ queries });
};

export default useGetAuditTrailParahub;
