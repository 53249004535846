import { Box, Text, Button, Flex, Spinner } from '@chakra-ui/react';
import ReactFlow, { Background, addEdge, Controls, useReactFlow, useNodesState, useEdgesState } from 'reactflow';
import { useCallback, useEffect, useState } from 'react';

import 'reactflow/dist/style.css';
import './styles.scss';
import { useAdditionalFlow } from './helpers/mainState';
import ButtonAdd from './sections/add';
import { onChangeCursor } from './helpers/events';
import { edgeTypes, nodeTypes } from './components/elements';
import { sortBy } from 'lodash';
import FloatingDetail from './components/floatingDetail';
import { onFetchDocs, onUpdateDocs } from '@pages/Documentation/helpers/crud';
import { ConditionalComponent } from '@components/functionComponent';

const ApplicationFlow = () => {
    const { setViewport, project } = useReactFlow();
    const [isLoading, setIsLoading] = useState(true);
    const { addType, onChangeAdditionalFlow, selectedElement } = useAdditionalFlow();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        setViewport({ y: 450, x: 1000, zoom: 1 });
    }, [setViewport]);

    useEffect(() => {
        onFetchDocs({
            type: 'application-flow',
            onChangeState: ({ docs }) => {
                setNodes(
                    docs?.contents?.nodes?.map((item) => {
                        return {
                            ...item,
                            data: {
                                ...item?.data,
                                position: item?.position,
                                onRemoveNode: handleOnRemoveNode,
                                onSelectElement: (selectedElement) => onChangeAdditionalFlow({ selectedElement }),
                            },
                        };
                    })
                );
                setEdges(
                    docs?.contents?.edges?.map((item) => {
                        return {
                            ...item,
                            type: 'defaultEdge',
                        };
                    })
                );

                setIsLoading(false);
            },
        });
    }, []);

    const onPaneClick = useCallback(
        (event) => {
            const { clientX, clientY } = event;

            const position = project({ x: clientX - 280, y: clientY - 170 });
            if (addType?.includes('node')) {
                setNodes((els) => {
                    const id = 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now().toString(36);
                    return [
                        ...els,
                        {
                            type: 'defaultNode',
                            id,
                            priority: 2,
                            position,
                            style: {
                                height: '48px',
                                width: '160px',
                            },
                            data: {
                                position,
                                id,
                                label: 'Nodes',
                                type: 'defaultNode',
                                onRemoveNode: handleOnRemoveNode,
                                onSelectElement: (selectedElement) => onChangeAdditionalFlow({ selectedElement }),
                                handle: {
                                    target: addType !== 'node-source',
                                    source: addType !== 'node-target',
                                },
                            },
                        },
                    ];
                });

                onChangeAdditionalFlow({ addType: null });
                onChangeCursor();
            }

            if (addType === 'default-note') {
                setNodes((els) => {
                    const id = 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now().toString(36);
                    return [
                        ...els,
                        {
                            type: 'defaultNote',
                            priority: 3,
                            id,
                            position,
                            style: {
                                height: '160',
                                width: '160px',
                            },
                            data: {
                                id,
                                position,
                                label: 'Notes',
                                type: 'defaultNote',
                                onRemoveNode: handleOnRemoveNode,
                                onSelectElement: (selectedElement) => onChangeAdditionalFlow({ selectedElement }),
                            },
                        },
                    ];
                });

                onChangeAdditionalFlow({ addType: null });
                onChangeCursor();
            }

            if (addType === 'group') {
                setNodes((els) => {
                    const id = 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now().toString(36);
                    return [
                        ...els,
                        {
                            id,
                            priority: 1,
                            position,
                            type: 'defaultGroup',
                            name: '',
                            style: {
                                width: 500,
                                height: 450,
                                background: '#0044861c',
                                border: '1px solid var(--main-color-dark)',
                            },
                            data: {
                                position,
                                id,
                                type: 'Group',
                                label: `Group - ${(Math.random() * 10).toFixed(1)}`,
                                onSelectElement: (selectedElement) => onChangeAdditionalFlow({ selectedElement }),
                                onRemoveNode: handleOnRemoveNode,
                            },
                        },
                    ];
                });

                onChangeAdditionalFlow({ addType: null });
                onChangeCursor();
            }
        },
        [nodes, addType]
    );

    const handleOnRemoveNode = useCallback(
        (id) => setNodes((nds) => nds.filter((node) => node.id !== id)),
        [setNodes, nodes]
    );

    const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge({ ...connection, type: 'defaultEdge', animated: true }, eds)),
        []
    );

    const onEdgeClick = useCallback((event, edge) => {
        event.preventDefault();
        onChangeAdditionalFlow({ selectedElement: edge });
    }, []);

    return (
        <Box className="folder-structure__container">
            <Flex justifyContent="space-between" alignItems="center" mb="64px">
                <Text className="title">Application flow</Text>
                <Button
                    className="button-save"
                    isLoading={isLoading}
                    onClick={async () => {
                        setIsLoading(true);
                        await onUpdateDocs({
                            type: 'application-flow',
                            title: 'Application Flow',
                            onChangeSnackbarNotification: () => console.log('Sss'),
                            value: {
                                edges,
                                nodes,
                            },
                        });

                        setIsLoading(false);
                    }}
                >
                    Save changes
                </Button>
            </Flex>
            <ConditionalComponent
                statement={!isLoading}
                fallback={
                    <Box className="spinner">
                        <Spinner color="pink" />
                    </Box>
                }
            >
                <Box className="content">
                    <ReactFlow
                        nodes={sortBy(nodes, ['priority'])}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        onPaneClick={onPaneClick}
                        edgeTypes={edgeTypes}
                        onEdgeClick={onEdgeClick}
                    >
                        <Controls></Controls>
                        <Background gap={16} color="var(--main-color-dark)" />
                    </ReactFlow>
                </Box>
            </ConditionalComponent>

         <ConditionalComponent statement={selectedElement?.id}>
            <FloatingDetail
                    setNodes={setNodes}
                    setEdges={setEdges}
                    edges={edges}
                    nodes={nodes}
                    selectedElement={selectedElement}
                    handleOnRemoveNode={handleOnRemoveNode}
                    onHide={() => onChangeAdditionalFlow({ selectedElement: null })}
                />
         </ConditionalComponent>

            <ButtonAdd setNodes={setNodes} />
        </Box>
    );
};

export default ApplicationFlow;
