import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import '../styles.scss';
import SkeletonComponent from '@components/skeleton';
import { IterationComponent } from '@components/functionComponent';

const AuditTrailParahubSkeleton = () => {
    return (
        <Box className="audit-trail-parahub-skeleton__container">
            <Box className="box-section" height={'184px'}>
                <Flex justifyContent={'space-between'} alignItems={'center'} mb="16px">
                    <SkeletonComponent height={'28px'} width={'181px'} />
                    <SkeletonComponent width={'324px'} height={'16px'} />
                </Flex>
                <Flex gap="24px" justifyContent="space-between">
                    <SkeletonComponent height={'90px'} width={'100%'} />
                    <SkeletonComponent height={'90px'} width={'100%'} />
                    <SkeletonComponent height={'90px'} width={'100%'} />
                    <SkeletonComponent height={'90px'} width={'100%'} />
                </Flex>
            </Box>

            <Box className="box-section">
                <SkeletonComponent height={'36px'} width={'300px'} mb="24px" />
                <Flex gap="24px" mb="24px" alignItems="center">
                    <SkeletonComponent height={'40px'} width={'300px'} />
                    <SkeletonComponent height={'40px'} width={'90px'} />
                    <SkeletonComponent height={'40px'} width={'132px'} />
                </Flex>

                <Table>
                    <Thead>
                        <Tr className="row-head__container row-skeleton">
                            <Th maxWidth="40px">
                                <SkeletonComponent width={'16px'} height={'16px'} />
                            </Th>
                            <Th>
                                <Flex alignItems="center" gap="8px">
                                    <SkeletonComponent width={'60px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px" float="left">
                                    <SkeletonComponent width={'80px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px">
                                    <SkeletonComponent width={'40px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px">
                                    <SkeletonComponent width={'80px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px">
                                    <SkeletonComponent width={'80px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px">
                                    <SkeletonComponent width={'40px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>

                            <Th>
                                <Flex alignItems="center" gap="8px">
                                    <SkeletonComponent width={'80px'} height={'17px'} />
                                    <SkeletonComponent width={'12px'} height={'12px'} />
                                </Flex>
                            </Th>
                            <Th>
                                <SkeletonComponent width={'36px'} height={'16px'} />
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <IterationComponent
                            arr={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                            render={(_, index) => (
                                <Tr className="row-column__container row-skeleton" key={index}>
                                    <Td padding="0 12px" maxWidth="40px">
                                        <SkeletonComponent width={'16px'} height={'16px'} />
                                    </Td>
                                    <Td>
                                        <SkeletonComponent width={'100px'} height={'20px'} />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent width={'100%'} height={'20px'} />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent width={'64px'} height={'20px'} />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent width={'100%'} maxWidth={'144px'} height={'20px'} />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent width={'100%'} maxWidth={'144px'} height={'20px'} />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent width={'100%'} maxWidth={'144px'} height={'20px'} />
                                    </Td>

                                    <Td>
                                        <SkeletonComponent width={'100px'} height={'20px'} />
                                    </Td>
                                    <Td>
                                        <SkeletonComponent float="center" width={'62px'} height={'36px'} />
                                    </Td>
                                </Tr>
                            )}
                        />
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
};

export default AuditTrailParahubSkeleton;
