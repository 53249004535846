import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold } from '@components/typography';
import SearchBar from '@components/searchBar';
import Export from '@pages/AuditTrailParahub/components/export';
import TableTemplate2 from '@components/tableTemplate2';
import { tableList } from './const';
import FilterList from '@pages/AuditTrailParahub/components/filterList';
import FilterButton from '../../components/filterButton';

import './styles.css';
import { useFilter } from '@pages/AuditTrailParahub/state/filters';
import { useMainState } from '@pages/AuditTrailParahub/state/state';
import { ConditionalComponent } from '@components/functionComponent';
import DetailModal from '@pages/AuditTrailParahub/components/detailModal';
import { find, reject, values } from 'lodash';
import HelpModal from '@pages/AuditTrailParahub/components/helpModal';
import useGetAuditTrailParahub from '@domain/parahub/useGetAuditTrailParahub';

const Table = () => {
    const { isOpen: isOpenFilter, onClose: onCloseFilter, onOpen: onOpenFilter } = useDisclosure();

    const filters = useFilter();
    const { data, isLoading } = useGetAuditTrailParahub(filters);
    const { selectedParahub, isOpenDetailModal, onChangeMainState, selectedExportParahub, isOpenHelpModal } =
        useMainState();

    const handlePageClick = ({ selected }) => filters.setFilter({ page: selected + 1 });
    const onResetSelectedRow = () => onChangeMainState({ selectedExportParahub: [] });
    const onCheckAllRow = () => onChangeMainState({ selectedExportParahub: data?.data });
    const onChangeSearch = (e) => filters.setFilter({ page: 1, searchQuery: e.target.value });
    const onChangeLimit = (e) => filters.setFilter({ page: 1, limit: e.target.value });

    const onChangeCheckbox = ({ values }) => {
        let arr = [...selectedExportParahub];
        const isExistSelectedParahub = find(selectedExportParahub, { id: values?.id });

        if (isExistSelectedParahub) arr = reject(arr, { id: values?.id });
        else arr.push(values);

        onChangeMainState({
            selectedExportParahub: arr,
        });
    };

    const onChangeBulkCheckbox = (value) => {
        onChangeMainState({
            selectedExportParahub: value,
        });
    };

    return (
        <Box className={`${styles['table__container']} table__container`}>
            <TextBold className={styles['title']}>Audit Trail - Sellout Parahub</TextBold>

            <Box className={styles['head']}>
                <Flex gap="24px">
                    <SearchBar placeholder="Cari nama customer atau ID" onChange={onChangeSearch} />
                    <FilterButton isOpen={isOpenFilter} onClose={onCloseFilter} onOpen={onOpenFilter} />
                    <Export />
                </Flex>
                <FilterList isOpen={isOpenFilter} onClose={onCloseFilter} onOpen={onOpenFilter} />
            </Box>

            <TableTemplate2
                isLoading={isLoading}
                data={data?.data}
                tableList={tableList}
                totalData={data?.totalData}
                totalPage={data?.totalPage}
                handlePageClick={handlePageClick}
                isPagination
                page={filters?.page}
                dataCount={data?.data?.length}
                onClickByRow={onChangeCheckbox}
                onResetSelectedRow={onResetSelectedRow}
                selectedRow={selectedExportParahub}
                onCheckAllRow={onCheckAllRow}
                limit={filters?.limit}
                onChangeBulkCheckbox={onChangeBulkCheckbox}
                onChangeLimit={onChangeLimit}
            />

            <ConditionalComponent statement={isOpenDetailModal}>
                <DetailModal data={selectedParahub} />
            </ConditionalComponent>

            <ConditionalComponent statement={isOpenHelpModal}>
                <HelpModal />
            </ConditionalComponent>
        </Box>
    );
};

export default Table;
