import authAxios from '@services/authAxios';

const fetchAuditTrailParahubErrorDetail = async ({ id }) => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/audit-trail/error/${id}`,
    });

    return data?.data;
};

export default fetchAuditTrailParahubErrorDetail;
