import { isEmpty } from 'lodash';

const getParahubListResponse = (response) => {
    if (isEmpty(response)) return [];

    return response?.map((item) => {
        return {
            id: item?.customer_id,
            name: `${item?.nama_outlet} (${item?.customer_id})`,
        };
    });
};

export default getParahubListResponse;
