import queryString from 'qs';

const getAuditTrailParahubRequest = (payload) => {
    const data = {
        nama_dc: payload?.dcName && `[${payload?.dcName}]`,
        customer_id: payload?.parahub?.length ? `[${payload?.parahub}]` : `[]`,
        date_sellout: payload?.dateSellout?.startDate
            ? `{${payload?.dateSellout?.startDate?.format('YYYY-MM-DD')},${(
                  payload?.dateSellout?.endDate ?? payload?.dateSellout?.startDate
              )?.format('YYYY-MM-DD')}}`
            : `{}`,
        date_uploaded: payload?.dateUpload?.startDate
            ? `(${payload?.dateUpload?.startDate?.format('YYYY-MM-DD')},${(
                  payload?.dateUpload?.endDate ?? payload?.dateUpload?.startDate
              )?.format('YYYY-MM-DD')})`
            : `()`,
        upload_type: payload?.selloutType,
        status: payload?.status,
        page: payload?.page,
        limit: payload?.limit,
        searchparam: payload?.searchQuery
            ? `customer_parahub_id.${payload?.searchQuery},customer_parahub_name.${payload?.searchQuery}`
            : '',
    };

    return queryString.stringify(data, { skipEmptyString: true, arrayFormat: 'separator' });
};

export default getAuditTrailParahubRequest;
