import { Box, Flex, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import { CloseIcon } from '@chakra-ui/icons';

import styles from './styles.module.css';
import { CopyBlueIcon, LeftArrowIcon } from '@assets/icons/index';
import { useMainState } from '@pages/AuditTrailParahub/state/state';

const HelpModal = () => {
    const { onChangeMainState } = useMainState();

    const onClose = () => {
        onChangeMainState({
            selectedParahub: {},
            isOpenHelpModal: false,
        });
    };

    const onBackPrevious = () => {
        onChangeMainState({
            isOpenHelpModal: false,
            isOpenDetailModal: true,
        });
    };

    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minWidth="560px" borderRadius="12px">
                <Box className={styles['detail-modal__container']}>
                    <Flex className={styles['head']}>
                        <Flex gap="12px">
                            <LeftArrowIcon onClick={onBackPrevious} />
                            <TextBold className={styles['title']}> Bantuan</TextBold>
                        </Flex>
                        <CloseIcon fontSize="12px" cursor="pointer" onClick={onClose} />
                    </Flex>

                    <Box className={styles['body']}>
                        <TextRegular mb="24px">
                            Silakan hubungi kontak di bawah ini untuk menindaklanjuti kegagalan data sellout yang masuk
                            ke platform Sagittarius melalui API.
                        </TextRegular>
                    </Box>

                    <Box className={styles['footer']}>
                        <Box className={styles['box']}>
                            <TextRegular color="var(--color-neutral40)" margin="0 0 8px">
                                Third Parties
                            </TextRegular>
                            <Flex gap="8px">
                                <TextRegular>+62856958530942</TextRegular>
                                <CopyBlueIcon />
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default HelpModal;
