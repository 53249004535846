import { Box, Flex } from '@chakra-ui/react';
import styles from './styles.module.css';
import { TextBold, TextRegular } from '@components/typography';
import { CloseRedIcon, CalendarBlueIcon, InformationIcon, SuccessGreen24Icon } from '@assets/icons/index';
import useGetAuditTrailParahubSummary from '@domain/parahub/useGetAuditTrailParahubSummary';
import periodeConverter from '@pages/AuditTrailParahub/helpers/periodeConverter';
import { ConditionalComponent } from '@components/functionComponent';
import SkeletonComponent from '@components/skeleton';

const Overview = () => {
    const { data, isLoading } = useGetAuditTrailParahubSummary();
    const periode = periodeConverter(data?.start_date, data?.end_date);

    return (
        <Box className={styles['overview__container']}>
            <Flex justifyContent="space-between" mb="16px">
                <TextBold className={styles['title']}>Audit Trail Summary</TextBold>
                <Flex gap="8px" className={styles['information__container']}>
                    <InformationIcon />
                    <ConditionalComponent
                        statement={!isLoading}
                        fallback={<SkeletonComponent height={'20px'} width={'250px'} />}
                    >
                        <TextRegular className={styles['last-update__text']}>
                            Terakhir diperbarui pada <span>{data?.sync_date ?? '-'} </span>
                        </TextRegular>
                    </ConditionalComponent>
                </Flex>
            </Flex>

            <Flex justifyContent="space-between" gap="24px">
                <Box className={styles['overview__item']}>
                    <Flex className={styles['overview-item__head']}>
                        <CalendarBlueIcon />
                        <TextRegular>Periode</TextRegular>
                    </Flex>
                    <ConditionalComponent
                        statement={!isLoading}
                        fallback={<SkeletonComponent height={'30px'} width={'100px'} />}
                    >
                        <TextBold className={styles['overview-item__text']}>{periode}</TextBold>
                    </ConditionalComponent>
                </Box>
                <Box className={styles['overview__item']}>
                    <Flex className={styles['overview-item__head']}>
                        <SuccessGreen24Icon width="16px" height="16px" />
                        <TextRegular>Sellout API Sukses</TextRegular>
                    </Flex>
                    <ConditionalComponent
                        statement={!isLoading}
                        fallback={<SkeletonComponent height={'30px'} width={'100px'} />}
                    >
                        <TextBold className={styles['overview-item__text']}>{data?.success_api ?? 0} Sellout</TextBold>
                    </ConditionalComponent>
                </Box>
                <Box className={styles['overview__item']}>
                    <Flex className={styles['overview-item__head']}>
                        <CloseRedIcon />
                        <TextRegular>Sellout API Gagal</TextRegular>
                    </Flex>
                    <ConditionalComponent
                        statement={!isLoading}
                        fallback={<SkeletonComponent height={'30px'} width={'100px'} />}
                    >
                        <TextBold className={styles['overview-item__text']}>{data?.failed_api ?? 0} Sellout</TextBold>
                    </ConditionalComponent>
                </Box>
                <Box className={styles['overview__item']}>
                    <Flex className={styles['overview-item__head']}>
                        <CloseRedIcon />
                        <TextRegular>Jumlah Error</TextRegular>
                    </Flex>
                    <ConditionalComponent
                        statement={!isLoading}
                        fallback={<SkeletonComponent height={'30px'} width={'100px'} />}
                    >
                        <TextBold className={styles['overview-item__text']}>{data?.total_error ?? 0} Error</TextBold>
                    </ConditionalComponent>
                </Box>
            </Flex>
        </Box>
    );
};

export default Overview;
