import { isEmpty } from 'lodash';

const getSelloutTypeResponse = (data) => {
    if (isEmpty(data)) return [];
    return data?.map((item) => {
        return {
            id: item?.name,
            name: item?.name,
        };
    });
};

export default getSelloutTypeResponse;
