import { Box } from '@chakra-ui/react';
import Overview from './section/overview';
import Table from './section/table';
import { useEffect } from 'react';
import { useMainState } from './state/state';
import { useFilter } from './state/filters';

const AuditTrailParahub = () => {
    const { resetMainState } = useMainState();
    const { resetFilter } = useFilter();

    useEffect(() => {
        return () => {
            resetFilter();
            resetMainState();
        };
    }, []);

    return (
        <Box>
            <Overview />
            <Table />
        </Box>
    );
};

export default AuditTrailParahub;
