import authAxios from '@services/authAxios';

const fetchAuditTrailParahubExport = async ({ queries }) => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/audit-trail/parahub/export?${queries}`,
    });

    return data;
};

export default fetchAuditTrailParahubExport;
