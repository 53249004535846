import { isEmpty } from 'lodash';

const getStatusResponse = (response) => {
    if (isEmpty(response)) return [];
    return response?.map((item) => {
        return {
            id: item?.name,
            name: item?.name,
        };
    });
};

export default getStatusResponse;
