import { Box, Flex } from '@chakra-ui/react';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import './styles.css';
import { BlackArrowIcon } from '@assets/icons/index';
import { TextBold, TextRegular } from '@components/typography';
import moment from 'moment';
import { formatCurrency } from '@helpers/utils';

const ChartBoxHover = ({ chartLabels, data, typeNominal, paddingLeft, year }) => {
    return (
        <Box className="chart-box-hover__container" paddingLeft={paddingLeft}>
            <IterationComponent
                arr={chartLabels}
                render={(month, index) => {
                    const sellout = data?.datasets[1]?.data[index];
                    const sellin = data?.datasets[0]?.data[index];

                    return (
                        <Box
                            className="chart-box-hover__item"
                            display={sellin >= 0 || sellout >= 0 ? 'inline' : 'none'}
                        >
                            <Box className="chart-box-hover__info" />
                            <Box className="chart-box-hover__floating-info">
                                <BlackArrowIcon />

                                <TextBold className="month-year__text">
                                    {month} {year}
                                </TextBold>

                                <ConditionalComponent statement={sellout >= 0}>
                                    <Flex mb="6px" alignItems="center">
                                        <Box className="total-sellout__box" />
                                        <TextRegular className="total-sellout__text">Total Sell-Out</TextRegular>
                                    </Flex>
                                    <TextBold className="total-sellout__number">
                                        {formatCurrency(sellout * typeNominal)}
                                    </TextBold>
                                </ConditionalComponent>

                                <ConditionalComponent statement={sellin >= 0}>
                                    <Flex mb="6px" alignItems="center">
                                        <Box className="total-sellin__box" />
                                        <TextRegular className="total-sellin__text">Total Sell-In</TextRegular>
                                    </Flex>
                                    <TextBold className="total-sellin__number">
                                        {formatCurrency(sellin * typeNominal)}
                                    </TextBold>
                                </ConditionalComponent>
                            </Box>
                        </Box>
                    );
                }}
            />
        </Box>
    );
};

export default ChartBoxHover;
