import getDCRequest from '@domain/model/request/dc/getDCRequest';
import getDCResponse from '@domain/model/response/dc/getSalesAreasResponse';
import getDC from '@hooks/dc/getDC';

const useGetDC = ({ enabled, search }) => {
    const queries = getDCRequest({ search });
    const responses = getDC({ enabled, queries });
    const mappingResult = getDCResponse(responses?.data);
    return { ...responses, data: mappingResult };
};

export default useGetDC;
