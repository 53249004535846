import React from 'react';
import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { TextBold, TextRegular } from '@components/typography';
import { InformationBlackIcon } from '@assets/icons/index';
import { Line } from 'react-chartjs-2';
import ChartBoxHover from '@pages/BusinessSiso/components/chartBoxHover';
import moment from 'moment';
import useGetSisoTrends from '@domain/siso/useGetSisoTrends';
import { useFilter } from '@pages/BusinessSiso/state/filters';

import './styles.css';
import 'chart.js/auto';
import { monthList } from '@helpers/const';
import { ConditionalComponent } from '@components/functionComponent';
import { useTrends } from '@pages/BusinessSiso/state/trends';
import TrendsSkeleton from '@pages/BusinessSiso/components/trendsSkeleton';
import TooltipComponent from '@components/tooltip';
import { TooltipTrendtontent } from '@pages/BusinessSiso/components/toolipContent';
import FilterYear from '@components/filter/year';

const Trend = () => {
    const filters = useFilter();
    const trends = useTrends();

    const { data, isLoading } = useGetSisoTrends(filters, trends);

    return (
        <Box className="trend__container">
            <Flex className="trend__head">
                <TextBold className="trend__title  no-highlight__text">
                    Trend Sell-In dan Sell-Out (Bulanan)
                    <TooltipComponent
                        content={<TooltipTrendtontent />}
                        maxWidth="512px"
                        textAlign="center"
                        positionArrowLeft="250px"
                    >
                        <InformationBlackIcon />
                    </TooltipComponent>
                </TextBold>

                <FilterYear onChange={(year) => trends.setTrends({ selectedYear: year })} />
            </Flex>

            <ConditionalComponent statement={!isLoading} fallback={<TrendsSkeleton />}>
                <Flex className="chart-toggle__container">
                    <Flex className="trend-sellin__container">
                        <Checkbox
                            checked={trends?.trendSellin}
                            defaultChecked={trends?.trendSellin}
                            onChange={(e) => trends.setTrends({ trendSellin: e.target.checked })}
                        />
                        <TextRegular className="trend-sellin__label">Sell-In</TextRegular>
                    </Flex>

                    <Flex className="trend-sellout__container">
                        <Checkbox
                            checked={trends?.trendSellout}
                            defaultChecked={trends?.trendSellout}
                            onChange={(e) => trends.setTrends({ trendSellout: e.target.checked })}
                        />
                        <TextRegular className="trend-sellout__label">Sell-Out</TextRegular>
                    </Flex>
                </Flex>

                <Box className="canvas-zone">
                    <Line
                        data={data?.chartData}
                        datasetIdKey="id"
                        id="line-chart__container"
                        options={data?.chartOptions}
                    />

                    <ChartBoxHover
                        paddingLeft={data?.chartHoverPaddingLeft}
                        chartLabels={monthList}
                        data={data?.chartData}
                        typeNominal={data?.typeNominal}
                        year={trends?.selectedYear}
                    />
                </Box>
            </ConditionalComponent>
        </Box>
    );
};

export default Trend;
