import { forEach } from 'lodash';

const getSummaryDetail = (data) => {
    let dataByBrand = [];

    forEach(data?.qty, (item, key) => {
        if (key !== 'total') dataByBrand.push({ ...item, brandName: key });
    });

    return {
        totalBrand: 2,
        totalSKU: data?.qty?.total?.sku_total,
        totalQty: data?.qty?.total?.qty_total,
        brands: dataByBrand,
    };
};

export default getSummaryDetail;
