import { SearchIcon } from '@assets/icons/index';
import { Flex, Input } from '@chakra-ui/react';
import './styles.css';

const SearchBar = ({ placeholder, onChange }) => {
    return (
        <Flex className="search-bar__container">
            <SearchIcon />
            <Input placeholder={placeholder} onChange={onChange} />
        </Flex>
    );
};

export default SearchBar;
