import fetchStatus from '@data/auditTrail/status/fetchStatus';
import { useQuery } from '@tanstack/react-query';

const getStatus = () => {
    return useQuery({
        queryKey: ['audit-trail-status'],
        queryFn: () => fetchStatus(),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
    });
};

export default getStatus;
