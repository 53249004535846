import fetchAuditTrailParahub from '@data/parahub/fetchAuditTrailParahub';
import { useQuery } from '@tanstack/react-query';

const getAuditTrailParahub = ({ queries }) => {
    return useQuery({
        queryKey: ['audit-trail-parahub', queries],
        queryFn: () => fetchAuditTrailParahub({ queries }),
        refetchOnWindowFocus: false,
        throwOnError: (error) => error,
        retry: false,
        gcTime: 0,
    });
};

export default getAuditTrailParahub;
