import getStatusResponse from '@domain/model/response/auditTrail/getStatusResponse';
import getStatus from '@hooks/auditTrail/getStatus';

const useGetStatus = () => {
    const responses = getStatus();
    const mappingResult = getStatusResponse(responses?.data);
    return { ...responses, data: mappingResult };
};

export default useGetStatus;
