import fetchAuditTrailParahubErrorDetail from '@data/parahub/fetchAuditTrailParahubErrorDetail';
import { useQuery } from '@tanstack/react-query';

const getAuditTrailParahubErrorDetail = ({ enabled, id }) => {
    return useQuery({
        queryKey: ['audit-trail-parahub-error-detail'],
        queryFn: () => fetchAuditTrailParahubErrorDetail({ id }),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
        enabled,
    });
};

export default getAuditTrailParahubErrorDetail;
