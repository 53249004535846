export const initialFilterState = {
    dc: [],
    parahub: [],
    enabledDC: false,
    selloutType: {},
    status: {},
    dateSellout: {},
    dateUpload: {},
};

export const emptyFilterState = {
    dc: [],
    parahub: [],
    enabledDC: false,
    selloutType: {},
    status: {},
    dateSellout: {},
    dateUpload: {},
};
