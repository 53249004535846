import authAxios from '@services/authAxios';

const fetchAuditTrailParahub = async ({ queries }) => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/audit-trail/parahub?${queries}`,
    });

    return { data: data?.data?.page_data, totalData: data?.data?.total_data, totalPage: data?.data?.total_page };
};

export default fetchAuditTrailParahub;
