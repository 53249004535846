import React from 'react';
import Login from '@pages/Login';
import UserManagement from '@pages/UserManagement';
import AddEditUserManagment from '@pages/UserManagement/components/addEdit';
import AuditTrail from '@pages/AuditTrail';
import UploadMapping from '@pages/UploadMapping';
import UploadSellout from '@pages/UploadSellout';
import BusinessSiso from '@pages/BusinessSiso';
import StoreConfig from '@pages/StoreConfig';
// import StoreConfigUpload from '@pages/StoreConfig/components/upload';
import ForbiddenPage from '@pages/Forbidden';
import StockTrail from '@pages/StockTrail';
import ResetPassword from '@pages/ResetPassword';
import ErrorPage from '@pages/ErrorPage';
import StoreConfigDetail from '@pages/StoreConfig/sections/detail';
import Profile from '@pages/Profile';
import Achievement from '@pages/Achievement';
import { AuditIcon, DashboardIcon, OrderIcon, StoreIcon, UploadIcon, UserManagementIcon } from './icons';
import OauthPage from '@pages/Oauth';
import Documentation from '@pages/Documentation';
import StoreConfigurationDetail from '@pages/storeConfigurationDetail';
import AuditTrailParahub from '@pages/AuditTrailParahub';

export const routeList = [
    {
        path: '/login',
        element: <Login />,
        name: 'Login',
        id: 'login',
        showInSideBar: false,
    },
    {
        path: '/oauth',
        element: <OauthPage />,
        name: 'oauth',
        id: 'oauth',
        showInSideBar: false,
    },

    {
        path: '/upload-sellout',
        icon: <UploadIcon />,
        name: 'Upload',
        showInSideBar: true,
        children: [
            {
                path: '/upload-sellout',
                id: 'upload_data',
                name: 'Sellout',
                element: <UploadSellout />,
            },
            {
                path: '/upload-mapping',
                id: 'upload_data',
                name: 'Mapping',
                element: <UploadMapping />,
            },
        ],
    },
    {
        path: '/order-management/create',
        icon: <OrderIcon />,
        name: 'Order Management',
        showInSideBar: true,
        children: [
            {
                path: '/order-management/create',
                element: <ForbiddenPage />,
                name: 'Create',
                id: 'order_management',
            },
            {
                path: '/order-management/active-order',
                element: <ForbiddenPage />,
                name: 'Active Order',
                id: 'order_management',
            },
            {
                path: '/order-management/history',
                element: <ForbiddenPage />,
                name: 'History',
                id: 'order_management',
            },
        ],
    },
    {
        path: '/audit-trail',
        icon: <AuditIcon />,
        name: 'Audit Trail',
        showInSideBar: true,
        children: [
            {
                path: '/audit-trail',
                id: 'audit_trail',
                showInSideBar: true,
                name: 'Sell Out - Store ',
                element: <AuditTrail />,
            },
            {
                path: '/audit-trail/parahub',
                id: 'parahub',
                showInSideBar: true,
                name: 'Sell Out - Parahub ',
                element: <AuditTrailParahub />,
            },
            {
                path: '/stock-trail',
                id: 'audit_trail',
                element: <StockTrail />,
                name: 'Stock',
            },
        ],
    },
    {
        path: '/business-siso',
        icon: <DashboardIcon />,
        name: 'Dashboard',
        showInSideBar: true,
        children: [
            {
                path: '/business-siso',
                id: 'siso_dashboard',
                name: 'Business SiSo',
                element: <BusinessSiso />,
            },
            {
                path: '/dashboard',
                id: 'dashboard',
                element: <Achievement />,
                name: 'Pencapaian',
            },

            // {
            //     path: '/product-performance',
            //     id: 'dashboard',
            //     element: <ForbiddenPage />,
            //     name: 'Product Performance  ',
            // },
        ],
    },
    {
        path: '/store-config/iseller',
        icon: <StoreIcon />,
        name: 'Store Configuration',
        showInSideBar: true,
        children: [
            {
                path: '/store-config/iseller',
                name: 'Integration',
                element: <StoreConfig />,
                id: 'configuration_store',
            },
            // {
            //     path: '/store-config/sagita',
            //     id: 'configuration_store',
            //     element: <ForbiddenPage />,
            //     name: 'Integrasi Sagita  ',
            // },
            // {
            //     path: '/store-config/detail',
            //     id: 'configuration_store',
            //     element: <ForbiddenPage />,
            //     name: 'Detail Toko',
            // },
        ],
    },
    {
        path: '/store-config/iseller/:id',
        id: 'configuration_store',
        name: 'Store Config',
        element: <StoreConfigurationDetail />,
        showInSideBar: false,
    },
    {
        path: '/store-configuration/iseller/:id',
        id: 'configuration_store',
        name: 'Store Config',
        element: <StoreConfigDetail />,
        showInSideBar: false,
    },
    {
        path: '/profile',
        id: 'profile',
        name: 'Profile',
        element: <Profile />,
        type: '*',
        showInSideBar: false,
    },

    // {
    //     path: '/store-config/upload',
    //     id: 'configuration_store',
    //     name: 'Store Config',
    //     element: <StoreConfigUpload />,
    //     showInSideBar: false,
    // },
    {
        path: '/user-management',
        icon: <UserManagementIcon />,
        name: 'User Management',
        element: <UserManagement />,
        id: 'user_management',
        showInSideBar: true,
    },
    {
        path: '/user-management/create',
        id: 'user_management',
        name: 'User Management',
        element: <AddEditUserManagment />,
        showInSideBar: false,
    },

    {
        path: '/user-management/edit/:id/:uniqueEmail',
        id: 'user_management',
        name: 'User Management',
        element: <AddEditUserManagment />,
        showInSideBar: false,
    },
    {
        path: '/documentation',
        element: <Documentation />,
        type: '*',
        name: 'Documentation',
        showInSideBar: false,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
        type: '*',
        name: 'Reset password',
        showInSideBar: false,
    },

    {
        path: '*',
        type: '*',
        element: <ForbiddenPage />,
        showInSideBar: false,
    },
];

const routeListMappingHandler = (routes) => {
    const arr = [];
    const routeListTemp = [...routes];

    //================ PUSH CHILDREN TO ROUTES ===========================

    routeListTemp?.forEach((route) => {
        route.children ? route.children.forEach((item) => arr.push(item)) : arr.push(route);
    });

    return arr;
};

export const routes = routeListMappingHandler(routeList);

export default routes;
