import getParahubListRequest from '@domain/model/request/parahub/getParahubListRequest';
import getParahubListResponse from '@domain/model/response/parahub/getParahubListResponse';
import getParahubList from '@hooks/parahub/getParahubList';
import { isEmpty } from 'lodash';

const useGetParahubList = (filters) => {
    const queries = getParahubListRequest(filters);
    const response = getParahubList({ enabled: !isEmpty(filters?.search) });
    const data = getParahubListResponse(response?.data);

    return { ...response, data };
};

export default useGetParahubList;
