import authAxios from '@services/authAxios';

const fetchAuditTrailParahubSummary = async () => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/audit-trail/parahub/error-summary`,
    });

    return data?.data;
};

export default fetchAuditTrailParahubSummary;
