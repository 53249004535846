import { Box, Flex } from '@chakra-ui/react';
import { TextBold } from '@components/typography';
import { FilterIcon } from '@assets/icons/index';

import { ConditionalComponent } from '@components/functionComponent';
import { useFilter } from '@pages/AuditTrailParahub/state/filters';

import checkFilterInformation from '@pages/AuditTrailParahub/helpers/checkFilterInformation';

import './styles.css';

const FilterButton = ({ isOpen, onOpen, onClose }) => {
    const filters = useFilter();

    const isOpenFilterInformation = checkFilterInformation(filters);

    const onClickFilter = () => {
        if (isOpen) onClose();
        else onOpen();
    };

    return (
        <Box className="filters-parahub__container">
            <Flex
                className="filter-button"
                onClick={onClickFilter}
                background={isOpen ? 'var(--semantic-heron)' : 'white'}
            >
                <FilterIcon />
                <TextBold className="filter-button__text no-highlight__text">Filter</TextBold>
                <ConditionalComponent statement={isOpenFilterInformation}>
                    <Box className="filter-indicator" />
                </ConditionalComponent>
            </Flex>
        </Box>
    );
};

export default FilterButton;
