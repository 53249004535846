import authAxios from '@services/authAxios';

const fetchParahubList = async () => {
    const { data } = await authAxios({
        method: 'GET',
        url: `/api/v1/audit-trail/parahub-store-name`,
    });

    return data?.data?.page_data;
};

export default fetchParahubList;
