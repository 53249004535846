import { useEffect, useState } from 'react';

const ClickjackingPrevention = ({ children }) => {
    // Check iframe status immediately during component creation
    const isInIframe = () => {
        try {
            return window !== window.top;
        } catch (e) {
            return true;
        }
    };

    // Set initial state immediately
    const [isFramed] = useState(isInIframe());

    useEffect(() => {
        if (isFramed) {
            try {
                if (window.top.location.hostname !== window.location.hostname) {
                    window.top.location = window.location;
                }
            } catch (e) {
                // Keep showing warning
            }
        }

        // Set security meta tags
        const setSecurityMetaTags = () => {
            if (!document.querySelector('meta[http-equiv="X-Frame-Options"]')) {
                const xFrameOptions = document.createElement('meta');
                xFrameOptions.httpEquiv = 'X-Frame-Options';
                xFrameOptions.content = 'DENY';
                document.head.appendChild(xFrameOptions);
            }

            if (!document.querySelector('meta[http-equiv="Content-Security-Policy"]')) {
                const csp = document.createElement('meta');
                csp.httpEquiv = 'Content-Security-Policy';
                csp.content = "frame-ancestors 'none'";
                document.head.appendChild(csp);
            }
        };

        setSecurityMetaTags();
    }, [isFramed]);

    if (isFramed) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#ffffff',
                    zIndex: 9999,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    userSelect: 'none',
                    pointerEvents: 'all',
                }}
            >
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h2
                        style={{
                            color: '#dc3545',
                            marginBottom: '10px',
                            fontSize: '24px',
                        }}
                    >
                        Security Alert
                    </h2>
                    <p
                        style={{
                            color: '#333',
                            fontSize: '16px',
                            maxWidth: '400px',
                            margin: '0 auto',
                        }}
                    >
                        This page has been blocked for security reasons. Please access the site directly through your
                        browser.
                    </p>
                </div>
            </div>
        );
    }

    return children;
};

export default ClickjackingPrevention;
