import fetchSelloutType from '@data/selloutType/fetchSelloutType';
import { useQuery } from '@tanstack/react-query';

const getSelloutType = ({ enabled }) => {
    return useQuery({
        queryKey: ['sellout-type'],
        queryFn: async () => await fetchSelloutType(),
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 0,
        enabled: enabled,
    });
};

export default getSelloutType;
