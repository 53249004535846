import { TextBold, TextRegular } from '@components/typography';
import styles from './styles.module.css';
import { Box, Checkbox, Flex } from '@chakra-ui/react';
import ButtonOutline from '@components/buttons/buttonOutline';
import { useMainState } from '@pages/AuditTrailParahub/state/state';
import { find, isEmpty, upperFirst } from 'lodash';
import { ChevronDownIcon } from '@assets/icons/index';
import { ConditionalComponent } from '@components/functionComponent';
import FloatingList from '@components/floatingList';
import { useState } from 'react';
import moment from 'moment';

const ActionButton = ({ values }) => {
    const { onChangeMainState } = useMainState();

    const onClickDetail = (event) => {
        event.stopPropagation();
        onChangeMainState({
            selectedParahub: values,
            isOpenDetailModal: true,
        });
    };

    return (
        <ButtonOutline
            onClick={onClickDetail}
            className={styles['detail__button']}
            color={'var(--color-primary)'}
            component={<TextBold color="var(--color-primary)">Detail</TextBold>}
        />
    );
};

const ExportCheckbox = ({ values }) => {
    const { selectedExportParahub } = useMainState();

    const isChecked = find(selectedExportParahub, { id: values?.id });

    return (
        <Flex className={styles['column__container']}>
            <Checkbox isChecked={!isEmpty(isChecked)} />
        </Flex>
    );
};

export const DateSellout = ({ value }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Box className="date-sellout-column__audit-trail">
            <Flex
                className={styles['column__container']}
                gap="4px"
                onClick={(event) => {
                    event.stopPropagation();
                    setIsOpen(true);
                }}
            >
                <TextRegular className={styles['column__text']}>{value[0] ?? '-'}</TextRegular>
                <ConditionalComponent statement={value?.length > 1}>
                    <ChevronDownIcon />
                </ConditionalComponent>
            </Flex>

            <ConditionalComponent statement={isOpen}>
                <FloatingList
                    arr={value}
                    renderElement={(item, idx) => (
                        <TextRegular className="date-item">{`${moment(item).format('DD MMM YYYY')}`}</TextRegular>
                    )}
                    onClose={() => setIsOpen(false)}
                />
            </ConditionalComponent>
        </Box>
    );
};

export const tableList = [
    {
        name: '',
        value: '',
        justifyContent: 'flex-start',
        width: 40,
        isCheckbox: true,
        component: ({ values }) => <ExportCheckbox values={values} />,
    },
    {
        name: 'Customer ID',
        value: 'customer_parahub_id',
        justifyContent: 'flex-start',
        component: ({ value }) => (
            <Flex className={styles['column__container']}>
                <TextRegular className={styles['column__text']}>{value ?? '-'}</TextRegular>
            </Flex>
        ),
    },
    {
        name: 'Customer',
        value: 'customer_parahub_name',
        justifyContent: 'flex-start',
        component: ({ value }) => (
            <Flex className={styles['column__container']}>
                <TextRegular className={styles['column__text']}>{value ?? '-'}</TextRegular>
            </Flex>
        ),
    },
    {
        name: 'DC',
        value: 'nama_dc',
        justifyContent: 'flex-start',
        component: ({ value }) => (
            <Flex className={styles['column__container']}>
                <TextRegular className={styles['column__text']}>{value ?? '-'}</TextRegular>
            </Flex>
        ),
    },
    {
        name: 'Parahub',
        value: 'nama_outlet',
        justifyContent: 'flex-start',
        component: ({ value }) => (
            <Flex className={styles['column__container']}>
                <TextRegular className={styles['column__text']}>{value ?? '-'}</TextRegular>
            </Flex>
        ),
    },
    {
        name: 'Sellout Date',
        value: 'date_sellout',
        justifyContent: 'flex-start',
        component: ({ value }) => <DateSellout value={value} />,
    },
    {
        name: 'Timestamp',
        value: 'date_uploaded',
        withSort: true,
        justifyContent: 'flex-start',
        component: ({ value }) => (
            <Flex className={styles['column__container']}>
                <TextRegular className={styles['column__text']}>{value ?? '-'}</TextRegular>
            </Flex>
        ),
    },
    {
        name: 'Status',
        value: 'status',
        width: 100,
        justifyContent: 'flex-start',
        component: ({ value }) => (
            <Flex className={styles['column__container']}>
                <TextRegular
                    p="4px 6px"
                    color={value === 'failed' ? 'var(--color-danger80)' : 'var(--color-success80)'}
                    bg={value === 'failed' ? 'var(--color-danger10)' : 'var(--color-success10)'}
                    className={styles['column__text']}
                >
                    {upperFirst(value) ?? '-'}
                </TextRegular>
            </Flex>
        ),
    },
    {
        name: 'Action',
        width: 100,
        justifyContent: 'flex-start',
        component: ({ values }) => <ActionButton values={values} />,
    },
];
