import moment from 'moment';

const periodeConverter = (startDate, endDate) => {
    startDate = moment(startDate).format('D');
    endDate = moment(endDate).format('D MMM YYYY');

    return `${startDate} - ${endDate}`;
};

export default periodeConverter;
