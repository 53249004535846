import { Box, Input } from '@chakra-ui/react';
import React from 'react';
import DatePicker from 'react-multi-date-picker';

import styles from './styles.module.css';
import { TextBold } from '@components/typography';
import { CalendarIcon } from '@assets/icons/index';

const FilterMultipleDate = ({ placeholder = '', onChange, value, width, title }) => {
    const selectedDateText = value?.startDate
        ? `${value?.startDate?.format('DD MMM YYYY')} - ${(value?.endDate ?? value?.startDate).format('DD MMM YYYY')}`
        : null;

    return (
        <Box className={styles['select-container']} width={width ?? '100%'}>
            <TextBold className={styles['select-title']}>{title}</TextBold>
            <DatePicker
                range={true}
                onChange={onChange}
                offsetX={-100}
                calendarPosition="bottom-right"
                value={[new Date(value?.startDate), new Date(value?.endDate)]}
                onOpenPickNewDate={false}
                render={(_, openCalendar) => (
                    <Box className={styles['box-filter']} onClick={openCalendar}>
                        <Input
                            className={styles['search-input']}
                            width={`${width} !important` ?? '100%'}
                            placeholder={placeholder}
                            value={selectedDateText}
                        />
                        <Box className={styles['search-icon']}>
                            <CalendarIcon />
                        </Box>
                    </Box>
                )}
            />
        </Box>
    );
};

export default FilterMultipleDate;
