import QueryString from 'qs';

const getAuditTrailParahubExportRequest = (payload) => {
    const data = {
        selected_id: payload?.length > 0 ? `[${payload?.map(({ id }) => id)}]` : `[]`,
        type: 'csv',
    };

    return QueryString.stringify(data, { skipEmptyString: true, arrayFormat: 'separator' });
};

export default getAuditTrailParahubExportRequest;
